export const faqs = [
    { 
        question: 'Сколько раз в неделю проходят занятия?',
        text: 'Младшие школьники занимаются 2 раза в неделю по 45 минут, подростки - 3 часа в неделю, взрослые - 2 дня в неделю по 90 минут.\n\nУ дошкольников занятия проходят 2-3 раза в неделю по 25-35 минут.'
    },
    { 
        question: 'Занятия проводятся по школьным учебникам?',
        text: 'Нет, мы ушли от школьной программы и проводим занятия по учебникам Британских изданий: из-во Кембридж, Оксфорд, Пирсон.\n\nЕсли родители согласны на приобретение учебника и тетради (комплект стоит около 2000 - 2500), то руководитель привезет Вам комплект.'
    },
    { 
        question: 'Что нужно для занятий?',
        text: 'Помимо комплекта учебников студентам нужна тетрадь для записей (лучше в клетку) и пенал с письменными принадлежностями.\n\nЕсли ребенок забыл что-то принести, преподаватель обеспечит всем необходимым (карандаш, ручка, резинка, цв. карандаши, листок бумаги).\n\nМы рекомендуем приносить сменную обувь для комфорта и гигиены ног.'
    },
    { 
        question: 'Что делать, если ученик пропустил урок?',
        text: 'Если урок был пропущен по уважительной причине (например, по причине болезни), то мы пригласим ученика в другой день и компенсируем пропущенный материал.\n\nПрогулы (пропуски по НЕуважительной причине) мы не отрабатываем, деньги за них не возвращаются.'
    },
    { 
        question: 'Задают ли учителя домашнее задание?',
        text: 'Да, мы считаем, что необходима дополнительная практика, поэтому задаем домашнее задание, которое студент в силах сделать самостоятельно и на которое уйдет от 5 до 20 минут.'
    },
    { 
        question: 'Можно ли совмещать занятия с другими кружками?',
        text: 'Да, мы стараемся найти компромисс в расписании, сделать его таким, чтобы студент мог совмещать обучение в Центре и на других кружках.'
    },
]