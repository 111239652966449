import agafonova from 'src/assets/webp_images/employer_agafonova.webp'
import koltsova from 'src/assets/webp_images/employer_koltsova.webp'
import selivanova from 'src/assets/webp_images/employer_selivanova.webp'
import chikaleva from 'src/assets/webp_images/employer_chikaleva.webp'
import shilkova from 'src/assets/webp_images/about_svet_shil_photo.webp'
import shaxalova from 'src/assets/webp_images/employer_shaxalova.webp'


export const employers = [
    {
        photo: agafonova,
        name: 'Агафонова Марина\nАлександровна',
        position: 'Преподаватель\nиностранных языков',
        text: 'В совершенстве владеет методикой преподавания, использует современные педагогические технологии и постоянно совершенствует свое педагогическое мастерство, ежегодно повышая квалификацию.'
    },
    {
        photo: koltsova,
        name: 'Кольцова Виктория\nЕвгеньевна',
        position: 'Преподаватель\nанглийского языка',
        text: 'Формирует у учащихся не только умения и навыки говорения, чтения, аудирования и письма, но и интерес к изучению языка, стараясь сделать урок не только познавательным, но и занимательным.'
    },
    {
        photo: selivanova,
        name: 'Селиванова Светлана\nВладимировна',
        position: 'Преподаватель ин. языков,\nметодист',
        text : 'Следит за качеством всех занятий, корректирует работу преподавателей, имеет международные сертификаты в сфере преподавания, которые подтверждают отличные знания по методике преподавания.'
    },
    {
        photo: chikaleva,
        name: 'Чикалёва Мария\nОлеговна',
        position: 'Преподаватель\nиностранных языков',
        text: 'На своих занятих делает упор на лексику и грамматику. Для подтверждения своей квалификации сдала Кембриджский экзамен ТКТ(teaching knowledge test), что показывает хороший уровень преподавания.'
    },
    {
        photo: shaxalova,
        name: 'Шахалова Евгения\nВикторовна',
        position: 'Администратор\nЦентра',
        text: 'Правая рука руководителя, человек, который наводит порядок в документах и всегда поможет найти потерявшуюся в Центре вещь, придет на помощь студентам, а также ответит на все вопросы родителей.'
    },
    {
        photo: shilkova,
        name: 'Шилкова Светлана\nАлександровна',
        position: 'Основатель, руководитель\nи преподаватель',
        text: 'Занимается обучением студентов, в основном подростков и взрослых, английскому, немецкому, французскому и испанскому языку. Любит находить нестандартные приемы и методы обучения.'
    },
]