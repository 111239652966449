export const reviews = [
    {
        review: 'Отличный центр, преподаватели - профессионалы своего дела.\n\nВсегда разнообразные, интересные уроки. Много интерактива, а не зубрежки.',
        name: 'Татьяна А.'
    },
    {
        review: 'Хороший центр. Отличные педагоги, квалифицированные, добрые, отзывчивые.\n\nВ центре чисто, удобное расписание, адекватные цены.',
        name: 'Светлана Синякова'
    },
    {
        review: 'В Inspiration созданы все условия для комфортного и эффективного изучения языков.\n\nГибкая система расписания занятий, широкий выбор языков для изучения, профессиональные педагоги.',
        name: 'Анна Ю.'
    },
    {
        review: 'Ребенок занимается в Центре с шести лет. В этом году я тоже начала изучать английский язык. С нуля. Рада, не жалею. Учителя сильные. Атмосфера на уроках дружелюбная.',
        name: 'Евгения Потолковская'
    },
    {
        review: 'Чтобы изучить и поддерживать разговорный английский язык советую данный центр. Ребенку нравится. В будущем можно попробовать и другие иностранные языки.',
        name: 'Елена Котова'
    }
]